import React, { useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import TrialSection from "../components/TrialSection"

const CarouselScreens = () => {
  const settings = {
    autoplay: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <Slider {...settings}>
      <div className="item">
        <div className="project">
          <div className="img">
            <img
              src="/ueofcweb/images/project-home.jpg"
              className="img-fluid"
              alt="ultronele screenshot"
            />
            <div className="text px-4">
              <h3>
                <a href="#">Hompage</a>
              </h3>
              <span>PC version</span>
            </div>
          </div>
        </div>
      </div>
      <div className="item">
        <div className="project">
          <div className="img">
            <img
              src="/ueofcweb/images/project-course.jpg"
              className="img-fluid"
              alt="ultronele screenshot"
            />
            <div className="text px-4">
              <h3>
                <a href="#">Course Detail</a>
              </h3>
              <span>PC version</span>
            </div>
          </div>
        </div>
      </div>
      <div className="item">
        <div className="project">
          <div className="img">
            <img
              src="/ueofcweb/images/project-mobile.jpg"
              className="img-fluid"
              alt="ultronele screenshot"
            />
            <div className="text px-4">
              <h3>
                <a href="#">Homepage</a>
              </h3>
              <span>Mobile version</span>
            </div>
          </div>
        </div>
      </div>
      <div className="item">
        <div className="project">
          <div className="img">
            <img
              src="/ueofcweb/images/project-service.jpg"
              className="img-fluid"
              alt="ultronele screenshot"
            />
            <div className="text px-4">
              <h3>
                <a href="#">Service Type</a>
              </h3>
              <span>Mobile version</span>
            </div>
          </div>
        </div>
      </div>
      <div className="item">
        <div className="project">
          <div className="img">
            <img
              src="/ueofcweb/images/project-path.jpg"
              className="img-fluid"
              alt="ultronele screenshot"
            />
            <div className="text px-4">
              <h3>
                <a href="#">Learning Path</a>
              </h3>
              <span>PC version</span>
            </div>
          </div>
        </div>
      </div>
      <div className="item">
        <div className="project">
          <div className="img">
            <img
              src="/ueofcweb/images/project-certificate.jpg"
              className="img-fluid"
              alt="ultronele screenshot"
            />
            <div className="text px-4">
              <h3>
                <a href="#">Create Certificate</a>
              </h3>
              <span>PC version</span>
            </div>
          </div>
        </div>
      </div>
    </Slider>
  )
}

const ProductPage = () => {
  // Code for FAQs page view for Google
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.gtag != null) {
        window.gtag("event", "conversion", {
          send_to: ["AW-11031846865/AXWBCMuGn4QYENG_sowp"],
        })
      }
    }
  })
  return (
    <Layout activeMenu="faqs">
      <SEO title="Faqs" />

      {/* <div className="banner_w3lspvt-2">
			<ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/" className="font-weight-bold">Home</Link>
        </li>
				<li className="breadcrumb-item" aria-current="page">Product</li>
			</ol>
		</div>

    <section className="ftco-section ftco-project " id="projects-section">
      <div className="container px-md-5">
        <CarouselScreens />
      </div>
    </section>

    <section id="services" className="section-padding bg-li py-5">
      <div className="container">
        <div className="section-header text-center">
          <h2 className="section-title wow fadeInDown" data-wow-delay="0.3s">Product Features</h2>
          <p className="sub-tittle text-center mt-3 mb-sm-5 mb-4">Ultronele&#39;s desire is to be a revolutionary elearning product featured with Speed, Usability, Enjoyment, Costlessness, Richness, Scalability, all these goals are reachable in the same time.</p>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-4 col-xs-12">
            <div className="services-item wow fadeInRight" data-wow-delay="0.3s">
              <div className="icon">
                  <i className="fas fa-rocket"></i>
              </div>
              <div className="services-content">
                <h3 className="feature-title"><a href="#">Speed</a></h3>
                <p>Leveraging the power of GatsbyJS framework, Ultronele give user an extremely soomth visit experience, no need to wait loading, its optimized content deliver to you without any delay.</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 col-xs-12">
            <div className="services-item wow fadeInRight" data-wow-delay="0.6s">
              <div className="icon">
                  <i className="fas fa-universal-access"></i>
              </div>
              <div className="services-content">
                <h3 className="feature-title"><a href="#">Usability</a></h3>
                <p>Simple and easy to use both in software deployment and content authoring, Ultronele utilize the themelization archetecture and content generator to simplify the whole process.</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 col-xs-12">
            <div className="services-item wow fadeInRight" data-wow-delay="0.9s">
              <div className="icon">
                  <i className="fas fa-puzzle-piece"></i>
              </div>
              <div className="services-content">
                <h3 className="feature-title"><a href="#">Enjoyment</a></h3>
                <p>Learning something unfamiliar may be not so interesting for most people, Ultronele solve this by introduce micro game or unlock bonus to encourage reader to open mind and keep delightful.</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 col-xs-12">
            <div className="services-item wow fadeInRight" data-wow-delay="1.2s">
              <div className="icon">
                  <i className="fas fa-funnel-dollar"></i>
              </div>
              <div className="services-content">
                <h3 className="feature-title"><a href="#">Costlessness</a></h3>
                <p>Setup and maintenance of a web application sounds like a big task for non-technicians, but Ultronele eliminates all your concern about it, by cutting database and using cloud service.</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 col-xs-12">
            <div className="services-item wow fadeInRight" data-wow-delay="1.5s">
              <div className="icon">
                  <i className="fas fa-layer-group"></i>
              </div>
              <div className="services-content">
                <h3 className="feature-title"><a href="#">Richness</a></h3>
                <p>Being an elearning system, ultronele also a great CMS if not including test. Authors can create their tutorials/curriculums with the syntax of Markdown file, and built-in templates.</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 col-xs-12">
            <div className="services-item wow fadeInRight" data-wow-delay="1.8s">
              <div className="icon">
                  <i className="fas fa-plug"></i>
              </div>
              <div className="services-content">
                <h3 className="feature-title"><a href="#">Scalability</a></h3>
                <p>By means of the plugin design from GatsbyJS, ultronele is also scalable to any new functionalities both in front-end and back-end. The components, page templates, plugins make it extremely flexible.</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 col-xs-12">
            <div className="services-item wow fadeInRight" data-wow-delay="1.8s">
              <div className="icon">
                <i className="fas fa-wave-square"></i>
              </div>
              <div className="services-content">
                <h3 className="feature-title"><a href="#">Trackability</a></h3>
                <p>Every user&#39;s learning process is recorded as learning path in local machine. Ultronele remember your each action and progress. Whenever you forget where you stop, turn to the path graph.</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 col-xs-12">
            <div className="services-item wow fadeInRight" data-wow-delay="1.8s">
              <div className="icon">
                <i className="fas fa-certificate"></i>
              </div>
              <div className="services-content">
                <h3 className="feature-title"><a href="#">Achievability</a></h3>
                <p>Ultronele is designed to encourage reader to walk through all the chapters/sections of each course by adding unlock bonus and certificate automatically creation at the end of test.</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 col-xs-12">
            <div className="services-item wow fadeInRight" data-wow-delay="1.8s">
              <div className="icon">
                <i className="fas fa-mobile-alt"></i>
              </div>
              <div className="services-content">
                <h3 className="feature-title"><a href="#">Mobility</a></h3>
                <p>For Ultronele administrator, authoring content and seeing the end result in a PC browser is the only option. But for the normal readers/learners, there is another option, it&#39;s mobile phone.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> */}

      <div className="about-inner py-5">
        <div className="container pb-xl-5 pb-lg-3">
          <div className="icon-effects-w3l">
            <img
              src="images/shape1.png"
              alt=""
              className="img-fluid shape-wthree shape-w3-one"
            />
            <img
              src="images/shape2.png"
              alt=""
              className="img-fluid shape-wthree shape-w3-two"
            />
            <img
              src="images/shape3.png"
              alt=""
              className="img-fluid shape-wthree shape-w3-three"
            />
            <img
              src="images/shape5.png"
              alt=""
              className="img-fluid shape-wthree shape-w3-four"
            />
            <img
              src="images/shape4.png"
              alt=""
              className="img-fluid shape-wthree shape-w3-five"
            />

            <img
              src="images/shape2.png"
              alt=""
              className="img-fluid shape-wthree shape-w3-seven"
            />
            <img
              src="images/shape3.png"
              alt=""
              className="img-fluid shape-wthree shape-w3-eight"
            />
            <img
              src="images/shape4.png"
              alt=""
              className="img-fluid shape-wthree shape-w3-nine"
            />
            <img
              src="images/shape5.png"
              alt=""
              className="img-fluid shape-wthree shape-w3-ten"
            />
          </div>
          <div className="row py-xl-4">
            <div className="col-lg-4 welcome-right mb-lg-0 mb-5"></div>
            <div className="col-lg-4 ">
              <h1
                className="mt-2 mb-3 text-center"
                style={{ color: "#E07845" }}
              >
                FAQs
              </h1>
            </div>
            <div className="col-lg-4"></div>
          </div>

          <ul className="accordion css-accordion mt-3">
            <li className="accordion-item">
              <input
                className="accordion-item-input"
                type="checkbox"
                name="accordion"
                id="item1"
              />
              <label htmlFor="item1" className="accordion-item-hd">
                Q: Why my child should learn coding?
                <span className="accordion-item-hd-cta">&#9650;</span>
              </label>
              <div className="accordion-item-bd">
                {/* <h6 className="accordion-textm">
                Q: Can a 7 years old learn coding?
              </h6> */}
                <p>
                  A: Enrolling your child in coding classes will help them
                  understand how to think logically and solve problems using the
                  power of programming languages. It also supports creativity
                  and experiments, allowing them to make anything from their
                  imagination. Apart from this, it is an extremely useful skill
                  for the future which can help them in getting employment
                  opportunities as well.
                </p>
              </div>
            </li>
            <li className="accordion-item">
              <input
                className="accordion-item-input"
                type="checkbox"
                name="accordion"
                id="item2"
              />
              <label htmlFor="item2" className="accordion-item-hd">
                Q: How old should be my child to take these courses?
                <span className="accordion-item-hd-cta">&#9650;</span>
              </label>
              <div className="accordion-item-bd">
                <p>
                  A: These classes are designed for kids aged 5-16 years old,
                  however , it's not limited to them only, anyone with an
                  interest and passion in coding is welcome to join our class (
                  including adults).
                </p>
              </div>
            </li>
            <li className="accordion-item">
              <input
                className="accordion-item-input"
                type="checkbox"
                name="accordion"
                id="item3"
              />
              <label htmlFor="item3" className="accordion-item-hd">
                Q: What is Roblox?
                <span className="accordion-item-hd-cta">&#9650;</span>
              </label>
              <div className="accordion-item-bd">
                <p>
                  A: Roblox is a very popular online game platform with
                  thousands of games. It also has a game creation system called
                  Roblox Studio that allow users to program their own games and
                  publish them online so that other users can play them.
                </p>
              </div>
            </li>
            <li className="accordion-item">
              <input
                className="accordion-item-input"
                type="checkbox"
                name="accordion"
                id="item4"
              />
              <label htmlFor="item4" className="accordion-item-hd">
                Q: What is python?
                <span className="accordion-item-hd-cta">&#9650;</span>
              </label>
              <div className="accordion-item-bd">
                <p>
                  A: Python is a high-level, general-purpose programming
                  language that is used for making software, websites,
                  automating tasks, machine learning, and artificial
                  intelligence. In addition to that, it is considered as the
                  easiest programming language to learn for kids and beginners.
                </p>
              </div>
            </li>
            <li className="accordion-item">
              <input
                className="accordion-item-input"
                type="checkbox"
                name="accordion"
                id="item5"
              />
              <label htmlFor="item5" className="accordion-item-hd">
                Q: What is Web development?
                <span className="accordion-item-hd-cta">&#9650;</span>
              </label>
              <div className="accordion-item-bd">
                <p>
                  A: Web Development is the process of building websites and web
                  applications. A person who builds a website is called a web
                  developer, the work includes designing the website, adding
                  functionality in it and making it live so that others can
                  access it online.
                </p>
              </div>
            </li>
            <li className="accordion-item">
              <input
                className="accordion-item-input"
                type="checkbox"
                name="accordion"
                id="item6"
              />
              <label htmlFor="item6" className="accordion-item-hd">
                Q: Are these group or private classes?
                <span className="accordion-item-hd-cta">&#9650;</span>
              </label>
              <div className="accordion-item-bd">
                <p>
                  A: We offer both group and private classes, private classes
                  are one-on-one where as in a group class there are usually 3
                  students and the duration of class is an hour.
                </p>
              </div>
            </li>
            <li className="accordion-item">
              <input
                className="accordion-item-input"
                type="checkbox"
                name="accordion"
                id="item7"
              />
              <label htmlFor="item7" className="accordion-item-hd">
                Q: What is the fee of these classes?
                <span className="accordion-item-hd-cta">&#9650;</span>
              </label>
              <div className="accordion-item-bd">
                <p>
                  A: Please send us a query through email at{" "}
                  <a href="mailto:hello@codingchamps.ca">
                    hello@codingchamps.ca
                  </a>{" "}
                  for pricing.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <TrialSection />
    </Layout>
  )
}

export default ProductPage
